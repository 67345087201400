import React, { useContext } from 'react';
import { BannerLayout, OrganisationTheme, PageContent } from '../interfaces/Theme';
import { AffiliateContent } from '../pages/ambassador';

interface ProviderProps{
  getLogoUrl: ()=> string;
  setIframeTheme: (element: HTMLIFrameElement, isIncludeInStep?: boolean)=>void;
  getTheme: ()=> OrganisationTheme;
  getOrganizationId: () => number;
  isServiceAvailableAt: (val:string) => boolean;
  getStripePulishableKey: () => string;
  getOrgName: () => string;
  isThemeLoaded:  boolean;
  isSuntuity: boolean;
  isClientOrg: boolean;
  isDifferentEnergies: boolean;
  setupTheme: (data:any) => void;
  getOrgSettings: () => OrganisationTheme["settings"];
  getToolConfigurations: () => { url: string; publicKey: string; };
  hasRouteAccess: (path?:string)=>boolean,
  getContent: () => OrganisationTheme["pageContent"];
  getTrustedPartners: () => OrganisationTheme["trustedPartners"];
  getHomeDynamicSections: () => OrganisationTheme["htmlContent"];
  getHomePageContent: ()=>PageContent["homepageContent"],
  getOrgFavicon: () => string;
  getHomeLayoutContent:(content?: OrganisationTheme['pageContent']['homepageContent'])=>BannerLayout,
  getLeaseConfig:()=>any,
  error:any;
  getOrgSlug:()=>string;
  getHostnameID: ()=>number;
  setTheme: (val: OrganisationTheme['cssVariables'])=>void
  sethostname: (val:{id: number; orgId: number; preview:boolean})=>void;
  isPreviewDomain: ()=>boolean;
  setAffiliatePageContent:(val: AffiliateContent)=> void
  isSinglePageLayout:boolean,
  singleFinanceLayout:boolean,
  stepFinanceLayout:boolean,
  isCustomWithCardFinanceLayout:boolean,
  isContractSignEnabled:boolean
  // isSunSource:boolean
}

const ThemeContext = React.createContext<ProviderProps>({} as ProviderProps);

export function useOrgTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined || !Object.keys(context ||{})?.length) {
    throw new Error('useOrgContext must be used within a ThemeProvider');
  }
  return context;
}

export default ThemeContext;

